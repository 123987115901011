@tailwind base;
@tailwind components;
@tailwind utilities;

/* global styles go here */
body,
html,
#__next {
  @apply h-full scroll-smooth lg:overscroll-none;
}

#__next > footer {
  top: 100vh;
  position: sticky;
}

/* Text styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: theme("fontFamily.primary");
}
h1,
h2,
h3,
h6 {
  @apply font-medium;
}
h4,
h5 {
  @apply font-semibold;
}

p {
  font-family: theme("fontFamily.secondary");

  @apply text-base font-normal pb-4 last:pb-0;
}

h1 {
  @apply text-l2 lg:text-xl2;
}

h2 {
  @apply text-l1 lg:text-xl1;
}

h3 {
  @apply text-m3 lg:text-l1;
}

h4 {
  @apply text-m2;
}

h5 {
  @apply text-m1;
}

h6 {
  @apply text-base;
}

.rich-text-ul,
.rich-text-ol {
  font-family: theme("fontFamily.secondary");
  padding-bottom: theme("spacing.4");
}

.rich-text-ul {
  list-style-type: disc;
}

.rich-text-ol {
  list-style-type: decimal;
}

.rich-text-ul li,
.rich-text-ol li {
  margin-left: theme("spacing.4");
}

.rich-text-ul li::marker,
.rich-text-ol li::marker {
  font-family: theme("fontFamily.secondary");
}

.rich-text-ul li p,
.rich-text-ol li p {
  @apply py-0;
}

.rich-text-ul li p:has(b),
.rich-text-ol li p:has(b) {
  padding-top: 16px;
}

blockquote p {
  font-family: theme("fontFamily.primary");
  font-weight: 500;
}

.bracket-link {
  @apply hover:before:content-[''] hover:before:bg-[url('public/assets/curly-bracket.svg')] hover:before:bg-[length:12px_100%] hover:before:bg-no-repeat before:absolute before:w-full before:-left-2 sm:before:-left-3 before:top-1/2 before:-translate-y-1/2 hover:content-[''] before:h-[62px] after:h-[62px] hover:after:bg-[url('public/assets/curly-bracket.svg')] hover:after:bg-[length:12px_100%] hover:after:bg-no-repeat after:absolute after:rotate-180 after:w-full after:top-1/2 after:-translate-y-1/2 after:-right-2 sm:after:-right-3 relative transition-all duration-300;
}

.eyebrow {
  @apply px-2 text-m1 font-semibold w-max relative before:content-[url('public/assets/grey-curly.svg')] before:absolute before:w-2 before:h-6 before:-left-1.5 before:top-0 after:content-[url('public/assets/grey-curly.svg')] after:absolute after:rotate-180 after:w-2 after:h-6 after:top-0 after:-right-1.5;
}

/* End Text styles */

/* Call to Action */
.primary-link,
.ghost-light-link,
.ghost-link,
.ghost-primary-link,
.primary-ghost-link {
  @apply px-[25px] py-3 w-full sm:max-w-max sm:w-max text-center text-base font-medium border-2 rounded-3 font-primary no-underline;
}

.primary-link {
  @apply border-secondary hover:border-tertiary bg-secondary hover:bg-tertiary text-primary disabled:text-grey-cool disabled:bg-grey-cool-tint;
}

.secondary-link {
  @apply text-base w-full sm:max-w-max font-primary font-medium text-white underline underline-offset-4 decoration-secondary hover:decoration-tertiary decoration-2 disabled:text-grey-cool disabled:no-underline;
}

.secondary-black-link {
  @apply text-base w-full sm:max-w-max font-primary font-medium text-black underline underline-offset-4 decoration-secondary hover:decoration-tertiary decoration-2 disabled:text-grey-cool disabled:no-underline;
}

.ghost-link {
  @apply border-secondary hover:border-tertiary;
}

.ghost-light-link {
  @apply border-secondary hover:border-tertiary text-white;
}

.primary-ghost-link {
  @apply border-secondary hover:border-tertiary bg-secondary hover:bg-tertiary text-primary disabled:text-grey-cool disabled:bg-grey-cool-tint lg:bg-[transparent] lg:hover:bg-[transparent] lg:text-[currentColor];
}

.ghost-primary-link {
  @apply border-secondary hover:border-tertiary lg:bg-secondary lg:hover:bg-tertiary text-white lg:text-primary disabled:text-grey-cool disabled:bg-grey-cool-tint;
}

.phone-link,
.chat-link {
  @apply text-base font-primary font-medium text-white underline underline-offset-4 decoration-secondary hover:decoration-tertiary decoration-2 disabled:text-grey-cool disabled:no-underline;
}

.inline-link {
  @apply underline hover:no-underline underline-offset-4 font-secondary decoration-solid;
}

.rich-text-underline {
  @apply underline;
}

.inline-link .rich-text-underline {
  @apply underline hover:no-underline;
}

.phone-link,
.chat-link {
  display: flex;
  gap: theme("spacing.1");
  align-items: center;
  width: max-content;
}
.phone-link::before,
.chat-link::before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.phone-link::before {
  background-image: url("public/assets/phone.svg");
  width: 20px;
  height: 20px;
}
.chat-link::before {
  background-image: url("public/assets/chat.svg");
  width: 20px;
  height: 20px;
}
/* End Call to Action */

/* Main Menu */
.main-menu {
  font-family: theme("fontFamily.primary");
  width: 100%;
  z-index: 100;
  transition-property: all;
  transition-duration: 300ms;

  @apply xl:pt-24 xl:flex xl:justify-between;
}

.main-menu > ul {
  max-width: theme("screens.xl");
  transition-property: all;
  transition-duration: 300ms;
  color: white;
  position: relative;
}

.main-menu > ul > li:has(ul > li.open-third-submenu + .third-level-items) > .nonlinked,
.main-menu > ul > li > ul:has(.open-third-submenu) > li.closed-third-submenu {
  display: none;
  @apply xl:block;
}

.main-menu:has(.closed-whole-submenu),
.main-menu > ul > li:has(ul > li.open-third-submenu + .third-level-items) > a {
  display: none;
}

.main-menu > ul:has(li.open-second-submenu) {
  transform: translateX(-100%);

  @apply xl:translate-x-0;
}

.main-menu:has(ul > li.open-second-submenu:not(.side-menu-item)) > ul:has(li.side-menu-item),
.main-menu:has(ul > li.open-second-submenu.side-menu-item) > ul:not(:has(li.side-menu-item)) {
  height: 0px;
  transform: translateX(-100%);

  @apply xl:translate-x-0 xl:h-auto;
}

.main-menu > ul:has(li.open-second-submenu) > li.closed-second-submenu {
  display: none;

  @apply xl:block;
}

.main-menu > ul > li > ul li a:hover,
.main-menu > ul > li > ul li .nonlinked:hover {
  @apply xl:underline;
}

.main-menu > ul > li {
  width: 100%;
  padding: 12px 0px;

  @apply xl:transition-all xl:pr-2 xl:pt-0 xl:pb-6 xl:duration-500 xl:origin-top xl:ease-in-out;
}

.main-menu > ul:has(li > ul > li.open-third-submenu + .third-level-items) {
  transform: translateX(-200%);
  @apply xl:translate-x-0;
}

.main-menu > ul > li.closed-whole-submenu {
  @apply xl:max-h-0 xl:opacity-0 xl:py-0;
}

.main-menu > ul > li > a,
.main-menu > ul > li > .nonlinked {
  font-size: theme("fontSize.m3");
  font-weight: 600;
  width: calc(100dvw - theme("spacing.16"));
  cursor: pointer;

  @apply xl:text-l1 xl:font-semibold xl:relative xl:w-max;
}

.main-menu > ul > li > a:hover {
  text-decoration: underline;
}

.main-menu > ul > li.open-second-submenu > a,
.main-menu > ul > li.open-second-submenu > .nonlinked,
.main-menu > ul > li > ul > li.open-third-submenu > a,
.main-menu > ul > li > ul > li.open-third-submenu > .nonlinked {
  color: theme("colors.secondary");

  @apply xl:text-white;
}

/* Second Level Submenu Items - Layout & Typography */
.main-menu > ul > li:not(.side-menu-item) > ul {
  position: relative;

  @apply xl:absolute xl:top-0 xl:left-full xl:max-w-xs xl:w-max xl:visible xl:pointer-events-auto transition-all duration-300;
}

.main-menu > ul > li.closed-second-submenu > ul {
  display: none;
}

.main-menu > ul > li.closed-second-submenu:not(.side-menu-item) > ul {
  @apply xl:block xl:opacity-0 xl:left-0 xl:-z-10 xl:pointer-events-none xl:invisible;
}

.main-menu > ul > li.open-second-submenu,
.main-menu > ul > li > ul > li.open-third-submenu,
.main-menu > ul > li > ul > li.open-third-submenu + .third-level-items {
  transform: translateX(100%);

  @apply xl:translate-x-0 xl:transform-none;
}

.main-menu > ul > li > ul > li {
  position: relative;
  padding: theme("spacing.3") theme("spacing.8");
  line-height: theme("spacing.8");

  @apply xl:pt-0;
}

.main-menu > ul > li > ul > li.open-third-submenu {
  padding: theme("spacing.3") theme("spacing.0");
  line-height: theme("spacing.8");

  @apply xl:pl-8 xl:pr-6 xl:pt-0;
}

.main-menu > ul > li > ul > li > a,
.main-menu > ul > li > ul > li > .nonlinked {
  font-weight: 600;
  font-size: theme("fontSize.m1");
  cursor: pointer;
}

.main-menu > ul > li > ul > li.open-third-submenu > a,
.main-menu > ul > li > ul > li.open-third-submenu > .nonlinked {
  font-size: theme("fontSize.m3");
  font-weight: 600;
  width: calc(100dvw - theme("spacing.16"));
  color: theme("colors.secondary");
  padding-left: 1rem;
  padding-right: 1rem;

  @apply xl:px-0 xl:w-auto xl:text-m1 xl:leading-8;
}

/* Third Level Submenu Items - Layout & Typography */
.main-menu > ul > li > ul > .third-level-items {
  position: relative;
  display: none;
  font-weight: 400;
  font-size: theme("fontSize.m1");
  width: 100%;
  pointer-events: none;
  transition-property: all;
  transition-duration: 300ms;

  @apply xl:absolute xl:block xl:opacity-0 xl:left-0 xl:top-0 xl:invisible xl:pointer-events-none xl:text-base;
}

.main-menu > ul > li > ul > li.open-third-submenu + .third-level-items {
  display: block;
  pointer-events: all;

  @apply xl:opacity-100 xl:left-full xl:visible xl:pointer-events-auto;
}

.main-menu > ul > li > ul > .third-level-items > ul > li {
  padding: theme("spacing.3") theme("spacing.8");

  @apply xl:pt-0;
}

.main-menu > ul > li > ul > .third-level-items > ul > li > a,
.main-menu > ul > li > ul > .third-level-items > ul > li > .nonlinked {
  @apply xl:underline hover:no-underline xl:font-secondary;
}

/* Sidebar Menu */
@screen xl {
  .main-menu > ul > li.side-menu-item,
  .main-menu > ul > li.side-menu-item:has(ul > li.open-third-submenu + .third-level-items) > a,
  .main-menu > ul > li.side-menu-item.closed-second-submenu > ul {
    display: block;
    padding-bottom: 12px;
  }

  .main-menu > ul > li.side-menu-item > ul > li {
    padding: 0px;
  }

  .main-menu > ul > li.side-menu-item > a,
  .main-menu > ul > li.side-menu-item > .nonlinked {
    padding-bottom: 4px;
    padding-right: 16px;
    cursor: text;
  }

  .main-menu > ul > li.side-menu-item > ul li a,
  .main-menu > ul > li.side-menu-item > ul li .nonlinked {
    text-decoration-line: underline;
    font-family: theme("fontFamily.secondary");
    font-size: theme("fontSize.base");
    font-weight: 400;
  }
  .main-menu > ul > li.side-menu-item > ul li a:hover,
  .main-menu > ul > li.side-menu-item > ul li .nonlinked:hover {
    text-decoration-line: none;
  }

  .main-menu > ul > li.side-menu-item > a::after,
  .main-menu > ul > li.side-menu-item > .nonlinked::after,
  .main-menu > ul > li.side-menu-item > ul > li:has(+ .third-level-items) > a::after,
  .main-menu > ul > li.side-menu-item > ul > li:has(+ .third-level-items) > .nonlinked::after,
  .main-menu > ul > li.side-menu-item > a::before,
  .main-menu > ul > li.side-menu-item > .nonlinked::before,
  .main-menu > ul > li.side-menu-item > ul > li:has(+ .third-level-items) > a::before,
  .main-menu > ul > li.side-menu-item > ul > li:has(+ .third-level-items) > .nonlinked::before {
    display: none;
  }
}
/* End Sidebar Menu */

/* Item with Nested Menu Items After Element */
.main-menu > ul > li > .nonlinked::after,
.main-menu > ul > li > ul > li:has(+ .third-level-items) > a::after,
.main-menu > ul > li > ul > li:has(+ .third-level-items) > .nonlinked::after {
  content: "›";
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  right: 0;
  padding-left: 12px;
  transition-property: all;
  transition-duration: 300ms;

  @apply xl:inline-block xl:transition-none xl:w-10 xl:h-9;
}
.main-menu > ul > li > a:hover::after,
.main-menu > ul > li > .nonlinked:hover::after {
  right: -16px;

  @apply xl:right-0;
}

.main-menu > ul > li > ul > li:has(+ .third-level-items) > a:hover::after,
.main-menu > ul > li > ul > li:has(+ .third-level-items) > .nonlinked:hover::after {
  right: -16px;
}

.main-menu > ul > li > ul > li:has(+ .third-level-items) > a::after,
.main-menu > ul > li > ul > li:has(+ .third-level-items) > .nonlinked::after {
  @apply xl:transition-all;
}

.main-menu > ul > li.open-second-submenu > a::after,
.main-menu > ul > li.open-second-submenu > .nonlinked::after {
  display: none;

  @apply xl:inline-block;
}

.main-menu > ul > li > ul > li.open-third-submenu > a::after,
.main-menu > ul > li > ul > li.open-third-submenu > .nonlinked::after {
  display: none;

  @apply xl:inline-block xl:-right-4 xl:rotate-180;
}
/* End Item with Nested Menu Items After Element */

/* Mobile Nested Menu Heading Before Element */
.main-menu > ul > li.open-second-submenu > a::before,
.main-menu > ul > li.open-second-submenu > .nonlinked::before,
.main-menu > ul > li > ul > li.open-third-submenu > a::before,
.main-menu > ul > li > ul > li.open-third-submenu > .nonlinked::before {
  content: "‹";
  position: relative;
  display: inline-block;
  left: 0;
  margin-right: 12px;
  transition-property: all;
  transition-duration: 300ms;

  @apply xl:content-[""] xl:mr-0 xl:transition-none;
}

.main-menu > ul > li.open-second-submenu > a:hover::before,
.main-menu > ul > li.open-second-submenu > .nonlinked:hover::before,
.main-menu > ul > li > ul > li.open-third-submenu > a:hover::before,
.main-menu > ul > li > ul > li.open-third-submenu > .nonlinked:hover::before {
  left: -16px;
}
/* End Mobile Nested Menu Heading Before Element */

/* First Level Menu Items Desktop Hover Effect */
.main-menu > ul > li > a::after {
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  right: 0;
  padding-left: 12px;
  transition-property: all;
  transition-duration: 300ms;

  @apply xl:inline-block xl:transition-none xl:w-10 xl:h-9 xl:absolute;
}

@screen xl {
  .main-menu > ul > li > a:hover::before,
  .main-menu > ul > li > .nonlinked:hover::before,
  .main-menu > ul > li.open-second-submenu > a::before,
  .main-menu > ul > li.open-second-submenu > .nonlinked::before {
    content: url("public/assets/header-link-curly-bracket.svg");
    width: 10px;
    height: 36px;
    position: absolute;
    top: 0px;
    left: -16px;
  }

  .main-menu > ul > li > a:hover::after,
  .main-menu > ul > li > .nonlinked:hover::after,
  .main-menu > ul > li.open-second-submenu > .nonlinked::after {
    background-image: url("public/assets/curly-bracket-right.svg");
    background-position: right;
    background-size: contain;
  }

  .main-menu > ul > li > a:hover::after {
    @apply xl:-right-4;
  }
}
/* End First Level Menu Items Desktop Hover Effect */

/* Main Menu Double Border */
@screen xl {
  .main-menu::before,
  .main-menu::after {
    content: "";
    height: 10px;
    width: 100%;
    position: absolute;
    border-bottom: 3px solid theme("colors.secondary");
    border-top: 3px solid theme("colors.secondary");
    left: 0px;
  }

  .main-menu::before {
    top: 120px;
  }
  .main-menu::after {
    bottom: 40px;
  }
}

/* End Main Menu Double Border */

/* Hamburger button */
.hamburger-btn rect {
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: all 0.3s ease-in-out;
}

.hamburger-btn svg.open .top-line {
  transform: rotate(45deg);
  transform-origin: center top;
  x: 12px;
  y: 12px;
}

.hamburger-btn svg.open .middle-line {
  opacity: 0;
}

.hamburger-btn svg.open .bottom-line {
  transform: rotate(-45deg);
  transform-origin: center top;
  x: -12px;
  y: 12px;
}

.hamburger-btn:hover rect {
  fill: theme("colors.tertiary");
}
/* End Hamburger button */

/* Header Overlay */
.header-image-overlay {
  animation-duration: 2s;
  animation-name: fadein;
  animation-timing-function: ease-out;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* End Header Overlay */
/* End Main Menu */

/* Dynamic Video */
.play-video:hover .circle {
  stroke: #cb9731;
}
.play-video:hover .triangle {
  fill: #cb9731;
}
/* End Dynamic Video */

/* Breadcrumbs */
.crumb::after {
  content: url("public/assets/bc-arrow.svg");
  position: absolute;
  right: 0.5rem;
}
/* End Breadcrumbs */

/* Search Bar */
.searchbar::before,
.searchbar-dark::before {
  content: "";
  width: 26px;
  height: 26px;
  background-image: url("public/assets/magnifying-glass.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 9px;
  top: 50%;
  transform: translateY(-54%);
  z-index: 50;
}

.searchbar input[type="text"],
.searchbar-dark input[type="text"] {
  width: 100%;
  font-family: theme(fontFamily.secondary);
  border-radius: theme(borderRadius.3);
  font-size: theme("fontSize.base");
  line-height: theme("fontSize.base");
  padding: theme("spacing[2.5]") 0 theme("spacing[2.5]") 2.5rem;
}

.searchbar input {
  font-family: theme(fontFamily.secondary);
  font-size: theme("fontSize.base");
  line-height: theme("fontSize.base");
}

#search-query,
.fake-searchbar {
  view-transition-name: search-field;
}

.search-results mark {
  @apply bg-secondary px-1 py-0;
}

/* Begin Search Page */
.pagefind-ui__form {
  @apply relative;
}

.pagefind-ui__search-input {
  @apply w-full relative font-secondary block py-2 pr-2 pl-9 transition-all border-2 rounded-sm placeholder:italic;
}

.pagefind-ui__form::before {
  content: "";
  background: url("public/assets/magnifying-glass.svg") no-repeat;
  display: block;
  position: absolute;
  width: 26px;
  height: 26px;
  @apply z-20 left-2 top-[8px];
}

.pagefind-ui__message {
  @apply font-primary my-10 pb-0 text-l2;
}

.pagefind-ui__search-clear {
  position: absolute;
  right: 1rem;
  top: 0.6rem;
}

.pagefind-ui__result-link {
  @apply text-l2 font-primary font-medium hover:underline;
}

.pagefind-ui__result-excerpt {
  @apply text-base text-grey-cool mt-0 mb-10;
}

.pagefind-ui mark {
  @apply bg-white font-primary text-tertiary rounded-md break-words;
}

.pagefind-ui__button {
  @apply primary-link;
}

.loading-indicator {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 3px;
  background:
    conic-gradient(from 135deg at top, #cb9731 90deg, #0000 0) 0 calc(50% - 2px) / 8.5px 4.25px,
    radial-gradient(farthest-side at bottom left, #0000 calc(100% - 3px), #cb9731 calc(100% - 2.5px) 99%, #0000) top
      right/50% 50% content-box content-box,
    radial-gradient(farthest-side at top, #0000 calc(100% - 3px), #cb9731 calc(100% - 2.5px) 99%, #0000) bottom / 100%
      50% content-box content-box;
  background-repeat: no-repeat;
  animation: s1 1s infinite linear;
}

@keyframes s1 {
  100% {
    transform: rotate(1turn);
  }
}

@screen lg {
  .searchbar-dark input {
    background-color: transparent;
    border: 1px solid white;
    color: white;
  }
}
/* End Search Bar */

/* Footer Form Section */
.global-form {
  display: grid;
  gap: theme("spacing.9");
  grid-template-areas:
    "image"
    "content"
    "inputs";
}

.global-form-image {
  grid-area: image;
}

.global-form-inputs {
  grid-area: inputs;
}

.global-form-content {
  grid-area: content;
}

@screen lg {
  .global-form {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas:
      "content content"
      "image inputs";
  }

  .global-form-content {
    grid-column: span 2 / span 2;
  }
}

/* End Footer Form Section */

/* Form Embed Section */

@screen lg {
  .form-brackets::before {
    content: "";
    mask: url("public/assets/large-curly-bracket.svg") no-repeat 50% 50%;
    mask-size: cover;
    -webkit-mask: url("public/assets/large-curly-bracket.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    display: inline-block;
    width: theme("spacing.40");
    height: 650px;
    position: absolute;
    left: -12rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .form-brackets::after {
    content: "";
    mask: url("public/assets/large-curly-bracket.svg") no-repeat 50% 50%;
    mask-size: cover;
    -webkit-mask: url("public/assets/large-curly-bracket.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    display: inline-block;
    width: theme("spacing.40");
    height: 650px;
    position: absolute;
    right: -12rem;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
  }

  .cta-sidebar-brackets::before {
    content: "";
    mask: url("public/assets/large-curly-bracket.svg") no-repeat 50% 50%;
    mask-size: cover;
    -webkit-mask: url("public/assets/large-curly-bracket.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    display: inline-block;
    width: theme("spacing.32");
    height: 350px;
    position: absolute;
    z-index: -10;
    left: -2rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .cta-sidebar-brackets::after {
    content: "";
    mask: url("public/assets/large-curly-bracket.svg") no-repeat 50% 50%;
    mask-size: cover;
    -webkit-mask: url("public/assets/large-curly-bracket.svg") no-repeat 50% 50%;
    -webkit-mask-size: cover;
    display: inline-block;
    width: theme("spacing.32");
    height: 350px;
    position: absolute;
    z-index: -10;
    right: -2rem;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
  }

  /* Class names based on the background color, not the color of the bracket. Therefore a section with a white background will have a light blue bracket and vice versa. */
  .form-brackets-primary::before,
  .form-brackets-primary::after {
    background-color: theme("colors.blue.dark");
  }

  .form-brackets-white::before,
  .form-brackets-white:after {
    background-color: theme("colors.highlight-tint");
  }

  .form-brackets-highlight-tint:before,
  .form-brackets-highlight-tint:after {
    background-color: theme("colors.white");
  }
}
/* End Form Embed Section */

.image-gradient-mask {
  mask-image: linear-gradient(to top, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 95%);
}

@screen md {
  .image-gradient-mask {
    mask-image: linear-gradient(to left, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 0) 95%);
  }
}
/* End Page Header */

/* Scrolling Section */
.scrollbar::-webkit-scrollbar {
  width: 36px;
  background-color: theme("colors.white");
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: theme("colors.grey.cool-tint");
}

.scrollbar::-webkit-scrollbar-button:vertical:start:increment {
  background-color: #10a2fc;
  background-image: url("public/assets/scroll-up.svg");
}

.scrollbar::-webkit-scrollbar-button:vertical:end:decrement {
  background-color: theme("colors.secondary");
  background-image: url("public/assets/scroll-down.svg");
}

.scrollbar::-webkit-scrollbar-button:vertical:start:increment:hover,
.scrollbar::-webkit-scrollbar-button:vertical:end:decrement:hover {
  background-color: theme("colors.tertiary");
}

.scrollbar::-webkit-scrollbar-button {
  @apply block h-[36px] cursor-pointer;
  background-repeat: no-repeat;
  background-position: center;
}

.scrollbar::-webkit-scrollbar-button:vertical:start:decrement,
.scrollbar::-webkit-scrollbar-button:vertical:end:increment {
  display: none;
}

@screen lg {
  .shadow-inset {
    box-shadow: inset 0 6px 10px 2px rgb(0 0 0 / 0.25);
  }
}
/* End Scrolling Section */

/* Page Divider */
.divider {
  background: repeating-linear-gradient(#10a2fc, #10a2fc 4px, #0000 4px, #0000 9px);
}

.page-content > .content-divider {
  @apply px-5;
}

.content-divider:has(+ .rich-text-heading) {
  margin-bottom: 16px;
}

/* End Page Divider */

/* CTA Banner */
.sidebar-form-section + .cta-banner-section {
  @apply hidden lg:block;
}
/* End CTA Banner */

/* Inline Image Gallery */

.sliderAnimation___300FY {
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform;
}

.gallery-image {
  object-fit: cover;
}

@screen md {
  .gallery-image {
    object-fit: contain;
  }
}

/* End Inline Image Gallery */

/* Practice Area */
.practice-area-archive-tab-bar::-webkit-scrollbar {
  display: none;
}

.individual-practice-area {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "table"
    "content"
    "reviews"
    "blog"
    "form";
}

.individual-practice-area .table-of-contents {
  grid-area: table;
  align-self: flex-start;

  @apply xl:justify-self-end;
}
.individual-practice-area .additional-info {
  grid-area: blog;
  align-self: flex-start;
  @apply xl:justify-self-end;
}
.individual-practice-area .practice-area-content {
  grid-area: content;
}
.individual-practice-area .practice-area-sidebar-form {
  grid-area: form;

  @apply xl:mr-5 xl:justify-self-start;
}

.individual-practice-area .sidebar-form {
  position: static;
}

.individual-practice-area .wins-and-reviews {
  grid-area: reviews;
}

@screen xl {
  .individual-practice-area {
    grid-template-areas:
      "table content form"
      "blog content reviews";
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 630px 1fr;
    gap: theme("spacing.7");
  }
}
/* End Practice Area */

/* Inline Image Gallery */

.sliderAnimation___300FY {
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform;
}

.gallery-image {
  object-fit: cover;
}

@screen md {
  .gallery-image {
    object-fit: contain;
  }
}

/* End Inline Image Gallery */

/* Star Rating */
@keyframes starfade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.star-rating .star {
  opacity: 0;
  animation-duration: 1s;
  animation-name: starfade;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
.star-rating .star:nth-child(2) {
  animation-delay: 0.25s;
}
.star-rating .star:nth-child(3) {
  animation-delay: 0.5s;
}
.star-rating .star:nth-child(4) {
  animation-delay: 0.75s;
}
.star-rating .star:nth-child(5) {
  animation-delay: 1s;
}
/* End Star Rating */

/* Archive Section */
.archive-tab-bar::-webkit-scrollbar {
  display: none;
}
/* End Archive Section */

/* Featured Team Member Section */
.featured-team-member-section {
  display: grid;
  grid-template-areas:
    "heading"
    "cards"
    "content";
  gap: theme("spacing.7");
}

@screen lg {
  .featured-team-member-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas:
      "heading cards"
      "content cards";
  }
}

.featured-team-member-heading {
  grid-area: heading;
}
.featured-team-member-content {
  grid-area: content;
}
.featured-team-member-cards {
  grid-area: cards;
}

.featured-team-member-section .team-member-card-name::before,
.featured-team-member-section .team-member-card-name::after {
  display: none;
  @apply lg:block;
}
/* End Featured Team Member Section */

/* Sidebar Form Section */
.sidebar-form-section-content > h2 {
  padding: 12px 0 24px;
}
/* End Sidebar Form Section */

/* Footer */
.social-link {
  color: theme("colors.white");

  transition: all;
  transition-duration: 200ms;
}
.social-link:hover {
  color: theme("colors.secondary");
}
/* End Footer */

.dimelo-chat-container {
  font-family: "Georgia", serif;
}
